import React, { useEffect, useState  } from 'react';
import { Link } from "gatsby"

import Layout from "components/Layout";
import Seo from 'components/Seo';
import Suggest from 'components/form/Suggest';

const Rider = ({ location }) => {

  return (
    <Layout className="layout-fixed">
      <Seo
        title={'Digital Transit Solutions'}
        pathname={location.pathname}
      />
      <section>
        <div  className="container">
          <h3>اخبرنا عن طريقك</h3>
        </div>
      </section>

      <section className="body-fixed">
        <div  className="container">

          <h5>عنوان الصعود</h5>

          <Suggest />
        </div>
      </section>
      <section>
        <div  className="container">
          <Link to="../2" role="button" className="secondary wide">المتابعة 2/9</Link>
        </div>
      </section>
    </Layout>
  );
}

export default Rider
